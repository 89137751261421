import React, { useRef, useState } from "react"; 
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion"; // Import Framer Motion
import "./ContactUs.css"; 

const Contact = () => {
  const form = useRef();
  const [notification, setNotification] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1vvb7jp",
        "template_4mcnmkw",
        form.current,
        "WljI9Oqm0RQqNEOrx"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!"); // Show success alert
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again."); // Show error alert
        }
      );
  };

  return (
    <div className="contact-container">
      <motion.h2 
        className="contact-header" 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.5 }}
      >
        Get in Touch with Us
      </motion.h2>

      <motion.div 
        className="contactform" 
        initial={{ opacity: 0, x: -100 }} 
        animate={{ opacity: 1, x: 0 }} 
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {notification && <p className="notification">{notification}</p>}
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
      </motion.div>

      <motion.div 
        className="map-container" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53118.939857444595!2d73.05641846629428!3d33.68477981392968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfea4aee5bdf8f%3A0xe6b55e05d462beb1!2sCOMSATS%20University%20Islamabad%20(CUI)!5e0!3m2!1sen!2s!4v1729079323145!5m2!1sen!2s"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="COMSATS University Islamabad Location"
        ></iframe>
      </motion.div>
    </div>
  );
};

export default Contact;
