import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PServices from './components/pages/PServices';
import SignUp from './components/pages/SignUp';
import about from './components/pages/About';
import ContactUs from './components/pages/ContactUs';
// import Contact from './components/ContactUs';
//import ContactUs from './components/pages/ContactUs';
import Project from './components/pages/Projects';
import { themeContext } from './Context';
import Awards from './components/pages/Awards';
import { useContext } from 'react';

function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <>
      <Router style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={PServices} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/about' component={about} />
          <Route path='/contactus' component={ContactUs} />
          <Route path='/projects' component={Project} />
          <Route path="/awards" component={Awards} />
        </Switch>
      </Router>
    </>
  );
}

export default App;