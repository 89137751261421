import React from 'react';
import { motion } from 'framer-motion';
import './StartPage.css';

const StartPage = () => {
    return (
        <div className="start-page-container">
            <motion.div 
                className="text-container"
                initial={{ opacity: 0, y: 20 }} 
                animate={{ opacity: 1, y: 0 }} 
                transition={{ duration: 1 }} 
            >
                <h1>Expertise in creating specific project solutions</h1>
            </motion.div>
            <motion.div 
                className="image-container"
                initial={{ opacity: 0 }} // Initial state
                animate={{ opacity: 1 }} // Final state
                transition={{ duration: 1.5 }} // Duration of the animation
            >
                <img src="/images/bg.jpg" alt="Expertise" />
            </motion.div>
        </div>
    );
};

export default StartPage;
