import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="custom-footer">
      <div className="footer-section information">
        <h3><FontAwesomeIcon icon={faInfoCircle} /> About</h3>
        <p style={{ marginBottom: '20px' }}> FAMBZZHH: Innovating health and engineering solutions. We specialize in product development and consultancy, focusing on the energy sector while forging strategic partnerships for impactful results.</p>
        <ul>
          <li><FontAwesomeIcon icon={faMapMarkerAlt} /> Shahzad, COMSATS University Islamabad-Main Campus, Tarlai Kalan, Park Rd, Chak, Islamabad.</li>
          <li><FontAwesomeIcon icon={faPhone} /> +92 3004845782</li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} /> 
            <a href="mailto:famzbbhhoffical@gmail.com" style={{ color: 'inherit', textDecoration: 'none' }}>
              famzbbhhoffical@gmail.com
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-section information">
        <h3><FontAwesomeIcon icon={faInfoCircle} /> Information</h3>
        <ul>
          <li><a href="/about">About</a></li>
          <div className="instagram-images">
          <img src="/images/fambzzhh.png" alt="Instagram 1" />
          <img src="/images/fambzzhh_group_photo.png" alt="Instagram 2" />
        </div>
          <li><a href="/services">Services</a></li>
          <div className="instagram-images">
          <img src="/images/S3.png" alt="Instagram 1" />
          <img src="/images/S7.jpg" alt="Instagram 2" />
        </div>
        </ul>
      </div>
      <div className="footer-section instagram">
        <div className="fi">
          <div className="instagram-images">
        <a href="/projects" style={{marginBottom:'15px'}}>Projects</a>
          <div className="instagram-images">
          <img src="/images/OM.png" alt="Instagram 1" />
          <img src="/images/drone.png" alt="Instagram 2" />
          <img src="/images/Meter.png" alt="Instagram 1" />
          <img src="/images/wtds.png" alt="Instagram 2" />
        </div>
        </div>
        
          <a href="/contactus">Contact</a>
        </div>
      </div>

      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src='images/FAMBZZHHnew.png' alt='Logo' className='logo-image' />
            </Link>
          </div>
          <small className='website-rights'>FAMBZZHH © 2024</small>
          <div className='social-icons'>
            <a className='social-icon-link facebook' href='https://www.facebook.com/profile.php?id=100067071000018&mibextid=ZbWKwL'>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a className='social-icon-link instagram' href='https://www.instagram.com/fambzzhh?igsh=N3gxdjFkcTN5ZHFx'>
              <i className='fab fa-instagram' />
            </a>
            <a className='social-icon-link youtube' href='https://www.youtube.com/@fambzzhhtechnology9860'>
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a className='social-icon-link twitter' href='https://x.com/fambzzhh?t=2-W5qtUvgcRLi_TwmO8FTg&s=09'>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a className='social-icon-link linkedin' href='https://www.linkedin.com/company/fambzzhh/'>
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
