import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './WhatWeOffer.css';

const WhatWeOffer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: true,
    });
  }, []);

  return (
    <div className="offer-container" data-aos="fade-up">
      <h2 className="offer-heading" data-aos="zoom-in">What We Offer</h2>
      <p className="offer-description" id="color1" data-aos="fade-up">
        At <strong>FAMBZZHH</strong>, we deliver <strong>innovative technical solutions</strong> driven by strategic management and a steadfast commitment to our clients and society. Our mission? To <strong>exceed your expectations</strong> and become your <strong>go-to partner</strong> for professional technical solutions.
      </p>

      <div className="offer-points">
        <div className="offer-point" data-aos="flip-left">
          <h3>We Listen & Understand</h3>
          <p>Your needs are our top priority. We dive deep into your requirements to craft tailored solutions that fit like a glove.</p>
        </div>
        <div className="offer-point" data-aos="flip-up">
          <h3>Precision in Action</h3>
          <p>Our meticulous approach ensures every detail is handled with care. We don't just work; we work with purpose.</p>
        </div>
        <div className="offer-point" data-aos="flip-right">
          <h3>Excellence Delivered</h3>
          <p>We pride ourselves on delivering exceptional results that make a difference. When you choose us, you choose the <strong>best output</strong>.</p>
        </div>
      </div>
      
      <p className="call-to-action" data-aos="fade-up">Let’s turn your vision into reality together!</p>
    </div>
  );
};

export default WhatWeOffer;
