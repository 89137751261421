import React from 'react';
import { useHistory } from 'react-router-dom'; 
import LeaderCard from './AwardCard';
import './AllAwards.css';

const leaders = [
  {
    title: 'Zindigi Prize KPK Regionals Wild Card Entry 2024',
    image: '/images/Picture1.png',
  },
  {
    title: 'Hong Kong Science and Technology Parks Corporation (HKSTP) Prize of HK$100,000',
    image: '/images/Picture2.jpg',
  },
  {
    title: 'Special Investment Facilitation Council (SIFC) Shield on June 5th, 2024, at Tulip Marquee',
    image: '/images/Picture3.jpg',
  },
  {
    title: 'RAIN-Y2 Exchange USA Origin 2024 Business Trip Funding for Women Empowerment Programme on May 29, 2024',
    image: '/images/Picture4.jpg',
  },
  {
    title: 'Idea Fusion Expo 2024 on April 30th, 2024, at the Business Incubation Center (BIC), COMSATS University Islamabad',
    image: '/images/Picture5.jpg',
  },
  {
    title: 'MNS Multan Special prize 2023',
    image: '/images/Picture6.jpg',
  },
  {
    title: 'Panel Discussion on AI arranged by Pakistan Engineering Council 2024',
    image: '/images/Picture7.jpg',
  },
  {
    title: "Unlocking Pakistan's Connectivity: Enablement of Next-Gen Wi-Fi in 6 GHz Band – PTA Event on May 16th, 2024, at Marriot Hotel, Islamabad",
    image: '/images/Picture8.jpg',
  },
  {
    title: "National CanSat Competition 2024 hosted by Space Education Research Lab (SERL), NCGSA",
    image: '/images/Picture9.jpg',
  },
  {
    title: "COMPPEC-2024 Project Expo at NUST EME College on May 2024",
    image: '/images/Picture10.png',
  },
  {
    title: "Chief guest at National CanSat Drone Competition on April 26th, 2024, at SERL, IST, Islamabad",
    image: '/images/Picture11.jpg',
  },
  {
    title: "Round Table Meeting at Shifa University on Industry-Academia Linkage Focusing Medical Device Development",
    image: '/images/Picture12.jpg',
  },
  {
    title: "Guest Speaker at Beacon House College at the STEM Symposium",
    image: '/images/Picture13.jpg',
  },
];

const Item = () => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack(); 
  };

  return (
    <div className="leadership">
      
      <div className="leadership-header">
        <h2>Our Awards</h2>
      </div>
      <div className="leader-cards">
        {leaders.map((leader, index) => (
          <LeaderCard key={index} {...leader} />
        ))}
      </div><button className="back-button" onClick={handleBackClick}>
        Back
      </button>
    </div>
  );
};

export default Item;
