import React from 'react';
import './Services.css';
import CardItem from './CardItem';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { motion } from 'framer-motion'; 
import "swiper/css";
import 'swiper/css/pagination';
import { useHistory } from 'react-router-dom'; 

function Services() {
  const history = useHistory();

  const awardsData = [
    {
      emoji: '/images/ai-driven.png',
      heading: "AI-Driven Hackathon CUI(wah) 2023",
      detail: "Won 3rd prize ,held on 2 August 2023 at CUI wah campus.",
      url: 'https://www.linkedin.com/posts/dr-amir-rashid-59350340_by-the-grace-of-allah-my-team-won-third-activity-7092489498923560961-33aM?utm_source=share&utm_medium=member_desktop',
    },
    {
      emoji: '/images/afs.png',
      heading: "AFS Venture Spark Competition 2023",
      detail: "Secured the 10k award in the Agriculture Engineering category with their innovative agriculture drone, held at MNS University in Multan on November 29-30, 2023.",
      url: "https://www.linkedin.com/posts/syedmeasum_representing-fambzzhh-technologies-at-afs-activity-7158243447596888064-Klqd?utm_source=share&utm_medium=member_desktop",
    },
    {
      emoji: '/images/fpcci_award.png',
      heading: "FPCCI Achievement Award 2022",
      detail: `Winner of the 10th FPCCI Achievement Award. 
Presented by Chairman Senate, Muhammad Sadiq Sanjrani, 
and Governor Punjab, Muhammad Baligh Ur Rehman.`,
      url: 'https://www.linkedin.com/posts/dr-amir-rashid-59350340_by-the-grace-of-allah-winner-of-10th-federation-activity-6993803980925464576-UcYe?utm_source=share&utm_medium=member_desktop',
    },
    {
      emoji: '/images/sac_leadership_dialog.png',
      heading: "SAC Leadership Dialog 2022",
      detail: "Won an entrepreneurial competition in IEEE CUI. Our Project was “IoT-based Smart meter” which resolves the problem of electricity theft and is very efficient.",
      url: 'https://www.linkedin.com/posts/dr-amir-rashid-59350340_by-the-grace-of-allah-my-team-won-ieee-cui-activity-6969415665979961344-WYIf?utm_source=share&utm_medium=member_desktop',
    },
    {
      emoji: '/images/national_idea_bank.png',
      heading: "National Idea Bank Competition 2021",
      detail: "In National Idea Bank Competition 2021,initially 2000 were short-listed out of 14000 participants, 200 were selected, and we got second position in education category.",
      url: 'https://www.facebook.com/photo.php?fbid=10162191264147580&set=a.10150689911467580&type=3&rdid=kZndWkG0bVNY2BOj&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FmBRAS4xnxv11ixU1%2F',
    },
    {
      emoji: '/images/pastic-uaf.png',
      heading: "PASTIC-UAF STEM & IT INNOVATIONS EXPO 2021",
      detail: "Won a cash prize stood among the top 4 out of 300 projects showcased at the University Of Agricultural Faisalabad. ",
      url:'https://www.facebook.com/story.php?story_fbid=10162182790322580&id=685847579&rdid=39B7iVlnamVlsOsj',
    },
    {
      emoji: '/images/Dice_2020.png',
      heading: "Dice-IET Innovation Event 2020",
      detail: "Get 3rd position out of 365 project in DICE 2020 competition Pakistan",
      url:'https://www.facebook.com/photo.php?fbid=10159480458947580&set=a.10150689911467580&type=3&rdid=3wTA0bnZV1hB0jMk&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FVvSpoJRY4bWTtCMU%2F',
    },
    {
      emoji: '/images/pakistan-science-foundation.png',
      heading: "Pakistan Science Foundation COVID 19",
      detail: `We developed the "AP Vent" ventilator.   
Fawad Ahmed Chaudhry, at the COVID-19 show in Islamabad. 
Gratefully, we're in the top 7.`,
      url:'https://www.facebook.com/photo.php?fbid=10159842908202580&set=a.10150689911467580&type=3&rdid=kjt3HjDLRVSqrg7o&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F86cuFQFJ8BFGp5bX%2F',
    },
  ];

  const handleViewAllAwards = () => {
    history.push('/awards');
  };

  return (
    <div className="services">
      {/* Left Side */}
      <div className="awesome">
        <span>Our Recent</span>
        <span>Awards</span>
        <span>
          - AI-Driven Hackathon 2023
          <br />
          - AfS Venture Spark 2023
          <br />
          - FPCCI Award 2022
          <br />
          - SAC Leadership Dialog 2022
          <br />
          - National Idea Bank Competition 2021
          <br />
          - PASTIC-UAF STEM & IT INNOVATIONS EXPO 2021
          <br />
          - Pakistan Science Foundation COVID 19
          <br />
          - VISIO SPARK 2019
          <br />
          - Youth Expo 2019
        </span>
        <button
          className="button s-button"
          onClick={handleViewAllAwards}
        >
          View All Awards
        </button>
      </div>

      {/* Right Side */}
      <Swiper
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={4}
        pagination={{ clickable: true }}
        className="service-slider"
      >
        <div className="swiper-wrapper">
          {awardsData.map((item, index) => (
            <SwiperSlide key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }} 
                animate={{ opacity: 1, y: 0 }} 
                exit={{ opacity: 0, y: -20 }} 
                transition={{ duration: 0.3, delay: index * 0.1 }} 
              >
                <CardItem
                  emoji={item.emoji}
                  heading={item.heading}
                  detail={item.detail}
                  url={item.url}
                />
              </motion.div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
}

export default Services;
