import React, { useEffect, useRef, useState } from 'react';
import './Experience.css';

const Experience = () => {
    const achievementsRef = useRef([]);
    const [counts, setCounts] = useState([0, 0, 0, 0]); 
    const targetValues = [9, 140, 70, 45]; 

    const setVisible = (index) => {
        if (achievementsRef.current[index]) {
            achievementsRef.current[index].classList.add('visible');
            startCounting(index); 
        }
    };

    const startCounting = (index) => {
        let currentCount = 0;
        const increment = targetValues[index] / 100; 
        const interval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= targetValues[index]) {
                currentCount = targetValues[index];
                clearInterval(interval);
            }
            setCounts((prevCounts) => {
                const newCounts = [...prevCounts];
                newCounts[index] = Math.floor(currentCount);
                return newCounts;
            });
        }, 20); 
    };

    useEffect(() => {
        const handleScroll = () => {
            achievementsRef.current.forEach((achievement, index) => {
                const rect = achievement.getBoundingClientRect();
                if (rect.top <= window.innerHeight - 50) { 
                    setVisible(index);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="experience">
            {Array.from({ length: 4 }).map((_, index) => (
                <div className='transformationx' key={index}>
                    <div className="achievement" ref={el => achievementsRef.current[index] = el}>
                        <div className="circle">{counts[index]}+</div>
                        <span>{index === 0 ? 'Years' : index === 1 ? 'Clients' : index === 2 ? 'Completed' : 'Awards'}</span>
                        <span>{index === 0 ? 'Experience' : index === 1 ? 'Handling' : index === 2 ? 'Projects' : 'Winning'}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Experience;
