import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { useAuth0 } from "@auth0/auth0-react";
function HeroSection() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className='hero-container'>
      
      <video src='/videos/video-2.mp4' autoPlay loop muted />
      <h1>FAMBZZHH</h1>
      <p>Where We Build Your Visions</p>
      {/* <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={() => loginWithRedirect()}
        >
          Sign-In
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
          
        >
         Sign Up <i className='far fa-play-circle' />
        </Button>
      </div> */}
    </div>
  );
}

export default HeroSection;
