import React from 'react';
import { motion } from 'framer-motion'; 
import "./CardItem.css";

const CardItem = ({ emoji, heading, detail, url }) => {
  return (
    <motion.div 
      className="carditem"
      whileHover={{ scale: 1.05 }} 
      transition={{ type: "spring", stiffness: 300 }} 
    >
      <img src={emoji} alt="" />
      <span>{heading}</span>
      <span>{detail}</span>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <button className="c-button">LEARN MORE</button>
      </a>
    </motion.div>
  );
};

export default CardItem;
