import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './Context';
import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
    domain="dev-igwmsghhvvz3vidk.us.auth0.com"
    clientId="c8inOpADbWFpakK0L8yktnPsR3uFNK3Q"
    authorizationParams={{
      redirect_uri: window.location.origin}}> 
      <ThemeProvider>
        <App />
      </ThemeProvider>
  </Auth0Provider>
);


