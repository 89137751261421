import React from 'react';
import './AwardCard.css';

const LeaderCard = ({ name, title, image }) => {
  return (
    <div className="leader-card">
      <img src={image} alt={name} className="leader-image" />
      <div className="leader-info">
        <h3>{name}</h3>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default LeaderCard;
