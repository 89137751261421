import React, { useState } from 'react';
import './MainService.css';
import { FaMicrochip, FaMobileAlt, FaCode } from 'react-icons/fa';

const services = [
  {
    title: 'PCB designing',
    description: 'We offer expert PCB design services tailored to meet diverse project requirements.',
    detailedDescription: 'We offer expert PCB design services tailored to meet diverse project requirements, utilizing industry-leading platforms such as EasyEDA, Altium, Proteus, and KiCad 8.0. From concept to production-ready layouts, our team delivers high-quality, optimized PCBs for various applications, ensuring precise signal integrity, thermal management, and component placement. Whether you need single-layer designs for simple projects or multi-layer, high-density boards for complex systems, our solutions are crafted to enhance performance and reliability. Partner with us to bring your ideas to life with professional, streamlined PCB designs customized to your needs.',
    image: '/images/S2.png', 
    detailedImage: '/images/S1.png', 
    icon: <FaMicrochip />,
  },
  {
    title: '3D modeling',
    description: 'Create realistic models to visualize and prototype your ideas.',
    detailedDescription: 'Bring your ideas to life with our advanced 3D modeling services! Using Blender and SolidWorks, we provide high-quality 3D designs tailored to meet your specific needs, whether for prototyping, product design, or animation. Our expert team ensures precision, detail, and creativity in every model, delivering assets that are both visually stunning and functionally optimized. From concept to completion, we work closely with clients to transform concepts into realistic, actionable 3D representations, enhancing design and production workflows.',
    image: '/images/S4.png', 
    detailedImage: '/images/S3.png',
    icon: <FaMobileAlt />,
  },
  {
    title: 'Software Development',
    description: 'Custom software solutions including web and mobile applications.',
    detailedDescription: 'Unlock the potential of your ideas with our robust and custom software development services. Specializing in Python and Java, we deliver high-quality solutions that cater to a wide range of applications—from web and desktop applications to automation and data-driven software. Our team brings deep technical expertise and a commitment to quality, ensuring software that is both efficient and scalable.Customized Solutions: Tailored software development that aligns with your unique requirements and business goals.End-to-End Service: From planning and development to testing and deployment, we cover every stage with precision.Modern Practices: We utilize the latest tools, libraries, and frameworks in Python and Java, enabling secure and high-performance solutions.Dedicated Support: Ongoing support and maintenance to keep your software optimized and up-to-date.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/sd.jpeg', 
    icon: <FaCode />,
  },
  {
    title: 'Embedded Solutions',
    description: 'Design and implement embedded systems for various applications.',
    detailedDescription: 'We provide comprehensive embedded solutions tailored to meet your specific requirements. Our expertise spans both hardware and software development, utilizing powerful languages like C, C++, Python, and Java. Whether you need custom firmware, real-time applications, IoT solutions, or complete hardware integration, we ensure your project is delivered with precision and efficiency. From concept to completion, we support every stage of your embedded system development to help you achieve optimized, reliable, and scalable solutions for your business.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S5.png', 
    icon: <FaCode />,
  },
  {
    title: 'IOT Solutions',
    description: 'Empowering businesses with innovative IoT solutions.',
    detailedDescription: 'Unlock the potential of the Internet of Things with our complete IoT solutions. Our services cover every aspect of IoT development, from hardware design and circuit integration to advanced software development in C, C++, Python, and Java. We deliver seamless connectivity and control with custom Android apps, enabling real-time data monitoring and management. Whether it is creating smart home systems, industrial automation, or remote monitoring, our end-to-end IoT solutions ensure efficient, scalable, and secure deployments tailored to meet your unique needs. Let us help bring your connected vision to life.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S6.jpg', 
    icon: <FaCode />,
  },
  {
    title: '3D printing',
    description: 'Transform your 3D designs into physical models.',
    detailedDescription: 'Bring your designs to life with our comprehensive 3D printing services. We offer precise and reliable 3D printing solutions compatible with models up to 250mm x 250mm x 250mm, perfect for prototyping, custom parts, and detailed models. Our expertise ensures high-quality prints with intricate details and robust structural integrity, making our service ideal for industrial, personal, and educational projects alike. Whether you need rapid prototyping or fully finished custom components, our team is here to provide fast, accurate, and affordable 3D printing solutions tailored to your needs.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S7.jpg',
    icon: <FaCode />,
  },
  {
    title: 'PCBA',
    description: 'Professional assembly of PCB components.',
    detailedDescription: 'Our PCBA (Printed Circuit Board Assembly) services offer fast, precise, and cost-effective solutions, ensuring top-quality assemblies with a quick turnaround. Leveraging advanced technology and rigorous testing, we guarantee reliable performance for a wide range of applications—from consumer electronics to industrial systems. Our services are designed to meet and exceed market standards, delivering impeccable quality at competitive prices. Whether for prototyping or full production runs, trust our team to deliver high-performance PCBA solutions tailored to your projects needs, every time.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S8.jpg',
    icon: <FaCode />,
  },
  {
    title: 'Game Development',
    description: 'Build immersive gaming experiences for various platforms.',
    detailedDescription: 'Unlock immersive gaming experiences with our cutting-edge Game Development services! Specializing in Unity and Unreal Engine, we create high-quality, engaging games tailored to various platforms—from mobile to PC and consoles. Our team brings your vision to life with stunning graphics, fluid gameplay, and captivating storylines. Whether you’re envisioning a simple 2D game or a complex 3D world, we offer end-to-end development, from concept design to testing and post-launch support. Partner with us to bring your gaming ideas to life with exceptional artistry and technical precision.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S9.png',
    icon: <FaCode />,
  },
  {
    title: 'Medical Devices',
    description: 'Developing innovative solutions for healthcare technology.',
    detailedDescription: 'Our Medical Device Design and Manufacturing services deliver precise, reliable, and user-friendly medical solutions tailored for modern healthcare needs. With expertise in developing devices like CPAP and BiPAP machines, thermal guns, oximeters, bedwetting alarms, and vital monitors, we offer full-cycle design and production to meet stringent medical standards. Each device is meticulously crafted for accuracy, ease of use, and compliance with health regulations, ensuring the highest quality and safety. From concept to finished product, our dedicated team of engineers and healthcare specialists works to turn ideas into life-enhancing devices that support both patients and medical professionals. Let us help bring your medical device innovations to life with unparalleled expertise and commitment to excellence.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S10.jpg',
    icon: <FaCode />,
  },
  {
    title: 'Android Development',
    description: 'Create robust Android apps to enhance user engagement.',
    detailedDescription: '•	Our Android Development Services provide customized, high-performance mobile solutions tailored to your specific business needs. Utilizing Android Studio and React Native, we create dynamic, engaging applications that deliver seamless user experiences across devices. Whether you need a native app for Android or a cross-platform solution, our team of expert developers combines creativity with technical expertise to develop apps that are intuitive, scalable, and optimized for performance. From concept to launch, we ensure your app not only meets but exceeds industry standards, enhancing your brand presence and user engagement on the go.',
    image: 'images/img-1.jpg', 
    detailedImage: '/images/S11.jpg',
    icon: <FaCode />,
  }
];


const ServiceSection = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleLearnMore = (service) => {
    setSelectedService(service);
  };

  const handleBack = () => {
    setSelectedService(null);
  };

  return (
    <div className="service-section">
      <h2 className="section-title" style={{color: 'orange'}}>Our Services</h2>
      {selectedService ? (
        <div className="service-detail">
          <div className="detail-content">
            <h3>{selectedService.title}</h3>
            <p>{selectedService.detailedDescription}</p>
            <button onClick={handleBack}>Back</button>
          </div>
          <div className="detail-images">
            <div
              className="detail-image"
              style={{ backgroundImage: `url(${selectedService.detailedImage})` }}
            ></div>
            <div
              className="detail-image"
              style={{ backgroundImage: `url(${selectedService.image})` }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="service-cards">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-image" style={{ backgroundImage: `url(${service.image})` }}></div>
              <div className="service-content">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <button onClick={() => handleLearnMore(service)}>Read More</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceSection;
