import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './ProjectItem.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Projects = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: true, 
        });
    }, []);
    const projectData = [
        { 
            id: 1, 
            name: "Ventilator", 
            bgImage: "images/about.avif", 
            details: [
                "Make every COVID-19 patient accessible at any hospital in the country.",
                "Portable ventilators will help patients stay quarantined at home, rather than in hospitals.",
                "The ventilators are to be designed with a much-reduced cost making them economy friendly.",
                "Local maintenance would be available at the designated locations.",
                "Easy to operate, even by a normal person."
            ],
            videoUrl: "https://www.youtube.com/watch?v=iaMCniwn-t0&t=21s"
        },
        { 
            id: 2, 
            name: "Pulse Oximeter", 
            bgImage: "images/about.avif", 
            frontImage: "/images/OM.png", 
            details: [
                "Pulse oximetry is a medical device used to measure oxygen saturation in blood without injecting a medical needle.",
                "It works by illuminating the human skin and measuring changes in light absorption of oxygenated and deoxygenated blood.",
                "Uses wavelengths of 660 nm (red) and 940 nm.",
                "Results can be seen on a mounted LCD on the device.",
                "Early detection of hypoxemia can lead to quicker treatment and prevent serious complications."
            ]  
        },
        { 
            id: 3, 
            name: "E SAVIOUR", 
            bgImage: "images/about.avif", 
            details: [
                "The plantation is fully automatic.",
                "The drone would also gather and keep the data of the total number of plants being planted in different months at a backend server.",
                "The drone is used for patrolling purposes.",
                "The camera attached to the drone would give live coverage of the area along with scanning of land for reforestation and agricultural purposes.",
                "For user information, the drone would also provide weather updates and GPS location on the mobile app."
            ] ,
            videoUrl: "https://www.youtube.com/watch?v=OxMCdixdtqM&t=101s"
        },
        { 
            id: 4, 
            name: "Shock preventor", 
            bgImage: "images/about.avif", 
            details: [
                "To enhance the shopping experience.",
                "To reduce the hustle during shopping.",
                "To save time by avoiding long queues.",
                "To provide a theft-free system.",
                "To experience a more interactive and useful system."
            ],
            videoUrl: "https://www.youtube.com/watch?v=jq9LV9slJTs&t=90s"
        },
        { 
            id: 5, 
            name: "Mobile hypnotism", 
            bgImage: "images/about.avif", 
            details: [
                "You can use these sections to highlight the features of heading."," Use these paragraphs to focus on the topic you want. Make sure you keep it short and attractive."
            ],
            videoUrl: "https://www.youtube.com/watch?v=XHRnntvTENI&t=25s"
        },
        { 
            id: 6, 
            name: "Accurate Position Monitoring APM", 
            bgImage: "images/about.avif", 
            details: [
               " Human encroachment and logging have resulted in Deforestation, which is currently one of the main and in-focus problems of the world."
            ],
            videoUrl: "https://www.youtube.com/watch?v=fU3Ac9DR8gg&t=41s"
        },
        { 
            id: 7, 
            name: "DRONE ON GUARD", 
            bgImage: "images/about.avif", 
            details: [
                "Drone of guard solution assists with monitoring and surveillance in all types of high-security infrastructure, hence enhancing security."
            ],
            frontImage:"/images/drone.png"
        },
        { 
            id: 8, 
            name: "IOT BASED SMART METER", 
            bgImage: "images/about.avif", 
            details: [
                "Sends you an SMS/Email of your electricity bill.",
                "Allows you to monitor energy usage anytime and from anywhere in the world.",
                "Digitalization of billing procedure.",
                "Reduces labor cost for meter reading.",
                "Secure and advanced technique."
            ],
            frontImage:"/images/Meter.png"
        },
        { 
            id: "thers",
            name: "Other Projects", 
            bgImage: "images/about.avif", 
            details: [
                "Real Time Android Based System for Student’s Attendance and Location Updates (RASSAL)",
                "Creation Watcher",
                "Sample Verification System (SVS)",
                "Abduction Redemption System",
                "Augmented Reality Teaching (A.R.T)",
                "Real Time Android Based Biometric Action Testing System (RABBATS)",
                "Dunger Care Smart System",
                "Following the Dreams"
            ],
        },
    ];

    const handleScrollToProject = (index) => {
        setActiveIndex(index);
        const projectElement = document.getElementById(`project-detail-${index}`);
        projectElement.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="projects-container">
            <div className="project-buttons">
                {projectData.map((project, index) => (
                    <div 
                        key={project.id} 
                        className="project-button" 
                        onClick={() => handleScrollToProject(index)}
                    >
                        <span className="project-number">{`0${project.id}`}</span>
                        <span className="arrow">➤</span>
                    </div>
                ))}
            </div>

            <div className="project-list">
                {projectData.map((project, index) => (
                    <div 
                        key={project.id} 
                        className={`project-card ${activeIndex === index ? 'active' : ''}`} 
                        id={`project-detail-${index}`}
                        style={{ backgroundImage: `url(${project.bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                        data-aos="fade-up" // Apply animation to each project
                    >
                        <div className="project-info">
                            <h2>{project.name}</h2>
                            {Array.isArray(project.details) ? (
                                <ul>
                                    {project.details.map((detail, idx) => (
                                        <li key={idx}>{detail}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{project.details}</p>
                            )}
                            {project.videoUrl && (
                                <ReactPlayer 
                                    url={project.videoUrl} 
                                    playing 
                                    loop 
                                    muted 
                                    controls 
                                    width="100%" 
                                    height="300px" 
                                />
                            )}
                        </div>
                        {project.frontImage && (
                            <img 
                                src={project.frontImage} 
                                alt={`${project.name} front`} 
                                className="front-image" 
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
