import React from 'react';

export default function SignUp() {
  const styles = {
    container1: {
      // backgroundImage: '/public/images/img-1.jpg', // Replace with your image path
      // backgroundSize: 'cover',
      // backgroundPosition: 'center',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    heading: {
      fontSize: '5rem',
      fontWeight: 'bold',
    },
  };

  return (
    <div style={styles.container1}>
      <h1 style={styles.heading}>LIKE & SUBSCRIBE</h1>
    </div>
  );
}
