import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Item from '../AllAwards';
function Awards() {
  return (
    <>
    <Item />
    <Footer />
    </>
  );
}

export default Awards;