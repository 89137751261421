import React from 'react'
import './Works.css'
import { motion } from "framer-motion";
import {Link} from 'react-scroll'

const Works = () => {
  return (
    <div className='works'>
        <div className="w-left">
            <div className="awesome">
                <span>Works for All these</span>
                <span>Brands & Clients</span>
                <span>
                - FPCCI Award 2022
                <br />
                - SAC Leadership Dialog 2022
                <br />
                - National Idea Bank Competition 2021
                <br />
                - PASTIC-UAF STEM & IT INNOVATIONS EXPO 2021
                <br />
                - Pakistan Science Foundation COVID 19
                <br />
                - VISIO SPARK 2019
                <br />
                - Youth Expo 2019
                
                </span>
                {/* <button className="button s-button">View All Awards</button> */}
            </div>
        </div>
        {/* right side */}
        <div className="w-right">
            <motion.div 
            initial={{ rotate: 45 }}
            whileInView={{ rotate: 0 }}
            viewport={{ margin: "-40px" }}
            transition={{ duration: 3.5, type: "spring" }}
            className="w-mainCircle">
                <div className="w-secCircle">   
                    <img src='/images/B1.png' alt="" />
                </div>
                <div className="w-secCircle">
                    <img src='/images/B2.png' alt="" />
                </div>
                <div className="w-secCircle">
                    <img src='/images/fiverr.png' alt="" />
                </div>{" "}
                <div className="w-secCircle">
                    <img src='/images/B3.png' alt="" />
                </div>
                <div className="w-secCircle">
                    <img src='/images/Facebook.png' alt="" />
                </div>
            </motion.div>
            {/* background Circles */}
            <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div>
        </div>
    </div>
    
  )
}

export default Works
