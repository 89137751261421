import React from 'react';
import "./Testimonials.css";

const Testimonials = () => {
    const clients = [
        
        {
            img: '/images/amir.jpg',
            review:
                "PhD in Communication Systems with expertise in mobile processing technologies. Proven problem solver and algorithm developer for hardware and software solutions. Extensive experience in finance. Meticulous, detail-oriented team player with strong interpersonal skills.",
            name: "Amir Rashid Chaudhary",
            position: "CEO",
            education: "PHD Communication Systems",
        },
        {
            img: '/images/iman.jpg',
            review:
                "I bring a robust engineering background and an MS in Project Management, merging technical proficiency with strategic leadership. My strength lies in seamlessly blending engineering principles with project management, resulting in the successful execution of intricate initiatives.",
            name: "Imaan Raja",
            position: "CEO FAMBZZHH Tech",
            education: "Ms Engineering Management",
        },
        {
            img: '/images/maazamir.jpg',
            review:
                "As a CTO with a BS in Electrical Engineering, I have successfully led over 100 projects, including groundbreaking work in medical and security equipment. My strong leadership qualities and ability to collaborate with diverse teams have been key to driving innovation and delivering impactful results. I am dedicated to aligning technology with business goals, ensuring that each project not only meets but exceeds expectations.",
            name: "Maaz Amir",
            position: "CTO",
            education: "Electrical Engineer",
        },
    ];

    return (
        <div className="t-wrapper">
            <div className="t-heading">
                <span style={{ color: " rgb(20, 40, 122)" }}>Executive Members</span>
                <br />
                <div style={{ textAlign: "center", color: 'orange' }}>
                    <span>Testimonials</span>
                </div>
                <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
                <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
            </div>
            <div className="testimonial-grid">
                {clients.map((client, index) => (
                    <div className="testimonial" key={index}>
                        <img src={client.img} alt={client.name} />
                        <span>{client.review}</span>
                        <span>{client.name}</span>
                        <span>{client.education}</span>
                        <span>{client.position}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Testimonials;
