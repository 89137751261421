import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Projects from '../ProjectItem';
import StartPage from '../Startpage';
function Project() {
  return (
    <>
      <h1 className='projects'></h1>
      <StartPage />
      <Projects />
      <Footer />
    </>
  );
}

export default Project;
