import React, { useEffect, useState } from 'react';
import "./Portfolio.css";

const images = [
  {
    src: '/images/sidebar.png',
    alt: 'Sidebar Project',
  },
  {
    src: '/images/ecommerce.png',
    alt: 'E-commerce Project',
  },
  {
    src: '/images/musicapp.png',
    alt: 'Music App Project',
  },
  {
    src: '/images/hoc.png',
    alt: 'HOC Project',
  },
];

const Portfolio = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showImages, setShowImages] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const portfolioSection = document.querySelector('.portfolio');
      const { top } = portfolioSection.getBoundingClientRect();
      const isVisible = top < window.innerHeight;

      if (isVisible) {
        setShowImages(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCircleClick = (index) => {
    setCurrentImageIndex(index); 
  };

  return (
    <div className="portfolio">
      {/* Heading */}
      <span>Recent Projects</span>
      <span>Portfolio</span>

      {/* Animated Project Row */}
      <div className="portfolio-slider">
        {showImages && (
          <div className="portfolio-image-container">
            <img 
              src={images[currentImageIndex].src} 
              alt={images[currentImageIndex].alt} 
              className={`portfolio-image ${showImages ? 'fade-in' : ''}`}
            />
          </div>
        )}
      </div>

      {/* Circles for Image Navigation */}
      <div className="circlenavigation">
        {images.map((_, index) => (
          <div
            key={index}
            className={`circle1 ${currentImageIndex === index ? 'active' : ''}`}
            onClick={() => handleCircleClick(index)} 
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
