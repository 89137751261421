import React from 'react';
import { motion } from 'framer-motion'; 
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <motion.div 
            className="Intro" 
            id="Intro" 
            initial={{ opacity: 0, y: 20 }} 
            animate={{ opacity: 1, y: 0 }} 
            transition={{ duration: 0.8 }} 
          >
            {/* left name side */}
            <motion.div 
              className="i-left" 
              whileHover={{ scale: 1.05 }} 
            >
              <div className="i-name">
                <span>Welcome To</span>
                <span>FAMBZZHH</span>
                <span>
                  FAMBZZHH isn't just another company, 
                  we're the a cappella harmony to life's messy 
                  challenges. We act as your financial 
                  conductor, weaving innovative solutions with 
                  care and precision. Join us on a vibrant 
                  journey towards a more balanced future, 
                  where prosperity echoes for you and society 
                  alike. Let's turn complexity into an 
                  elegant tune – together.
                </span>
              </div>
              <div className="i-icons">
                <a href='https://www.instagram.com/fambzzhh?igsh=N3gxdjFkcTN5ZHFx'>
                  <img src='/images/github.png' alt="" />
                </a>
                <a href='https://www.linkedin.com/company/fambzzhh/'>
                  <img src='/images/linkedin.png' alt="" />
                </a>
                <a href='https://www.instagram.com/fambzzhh?igsh=N3gxdjFkcTN5ZHFx'>
                  <img src='/images/instagram.png' alt="" />
                </a>
              </div>
            </motion.div>
            {/* right image side */}
            <motion.div 
              className="i-right" 
              whileHover={{ scale: 1.05 }} 
            >
              <div className='cards__container'>
                <div className='cards__wrapper'>
                  <img src='/images/fambzzhh_group_photo.png' alt="" />
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
