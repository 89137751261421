import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Team from '../Team';
import WhatWeOffer from '../WhatWeOffer';
import Card from '../MainAbout';
 function about() {
  return(
    <>
  {/* <h1 className='about'></h1> */}
    <Card />
    <Team />
    <WhatWeOffer />
    <Footer />
    </>
  ); 
}export default about;
