import React from 'react';
import { motion } from 'framer-motion';
import './MainAbout.css';

const Card = () => {
  return (
    <motion.div 
      className="card-background"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div 
        className="card-container"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="card">
          <div className="card-left">
            <h2>WHO WE <br /> ARE!</h2>
          </div>
          <div className="card-right">
            <p>
            At our core, we don’t just think outside the box—we redefine it. Like a masterful a cappella ensemble, we blend precision and passion, crafting success stories that resonate. Committed to enhancing society, we specialize in product development, creating cutting-edge medical equipment, pioneering drone technology, and designing advanced embedded PCBs. With every challenge we tackle, we leave a legacy of ingenuity and impact.
            </p>
          </div>
        </div>

        <motion.div 
          className="about-us-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <h2>Our Story</h2>
          <p id='color'>
            Fambzzhh is a dynamic multinational company established in 2015. Since our inception, we have built a strong reputation for delivering exceptional products within impressive timeframes. Our diverse team consists of talented engineers who understand the industry's demands, crafting projects that meet international standards while contributing to the betterment of society.
          </p><hr></hr>
          <h2>Our Mission</h2>
          <p id='color'>
            At Fambzzhh, we are committed to creating value for our clients. We deliver reliable and effective products tailored to elevate your business in today’s competitive marketplace.
          </p><hr></hr>
          <h2>Why Choose Us?</h2>
          <p id='color'>
            Fambzzhh isn’t just about business; it's about building a better future through innovation and collaboration. Let’s embark on this journey together!
          </p><hr></hr>
          <br />
          <h2 id='centre'>Our Approach</h2>
          <motion.div 
            className="values-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.7 }}
          >
            <div className="value-card">
              <h3>Productivity</h3>
              <p>Streamlining processes to maximize efficiency.</p>
            </div>
            <div className="value-card">
              <h3>Continuous Development</h3>
              <p>Fostering an environment of perpetual growth.</p>
            </div>
            <div className="value-card">
              <h3>Work Ethics</h3>
              <p>Instilling integrity and dedication in every team member.</p>
            </div>
          </motion.div>
          <br />
          <h2 id='centre'>Our Values</h2>
          <motion.div 
            className="values-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.9 }}
          >
            <div className="value-card">
              <h3>Professional Specialists</h3>
              <p>A team of experts ready to innovate.</p>
            </div>
            <div className="value-card">
              <h3>Brilliant Ideas</h3>
              <p>Creativity that fuels our projects.</p>
            </div>
            <div className="value-card">
              <h3>Precise Builders</h3>
              <p>Attention to detail in every construction.</p>
            </div> 
            <div className="value-card">
              <h3>24/7 Assistance</h3>
              <p>Always here to support our clients.</p>
            </div> 
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Card;
