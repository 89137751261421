import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Team.css';

const teamMembers = [
  {
    id: 1,
    name: 'Amir Rashid Chaudhary',
    role: 'CEO',
    description: '',
    imageUrl: '/images/amir.jpg', 
  },
  {
    id: 2,
    name: 'Imaan Raja',
    role: 'CEO FAMBZZHH Tech',
    description: '',
    imageUrl: '/images/iman.jpg', 
  },
  
  {
    id: 3,
    name: 'Maaz Amir',
    role: 'CTO',
    description: '',
    imageUrl: '/images/maazamir.jpg', 
  },
  
];

const Team = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: true, 
    });
  }, []);

  return (
    <div className="team-container">
      <h2 className="team-heading" data-aos="fade-up">
        We Are The People Who Make Up <br />FAMBZZHH
      </h2>
      <div className="team-cards">
        {teamMembers.map((member) => (
          <div 
            key={member.id} 
            className="team-card" 
            data-aos="fade-up" 
            data-aos-delay={`${member.id * 100}`} 
          >
            <img src={member.imageUrl} alt={member.name} className="team-photo" />
            <h3 className="team-name">{member.name}</h3>
            <p className="team-role">{member.role}</p>
            <p className="team-description">{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
