import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ServiceSection from '../MainService';
 function PServices() {
  return(
  <>
  {/* <h1 className='pservices'>SERVICES</h1> */}
    <ServiceSection />
    <Footer />
    </>
  );
  
}export default PServices;
