import React from 'react';
import '../../App.css';
import Intro from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Services from '../Services';
import Experience from '../Experience';
import Works from '../Works';
 import Portfolio from '../Portfolio';
import Testimonials from '../Testimonials';
import Contacts from '../Contacts';

function Home() {
  return (
    <>
      <HeroSection />
      <Intro />
      <Services />
      <Works />
      <Portfolio />
      <Testimonials />
      <Experience />
      <Contacts />
      <Footer />
    </>
  );
}

export default Home;
